import "./App.css";

import React, { useEffect } from "react";
import Header from "./components/Header";
import Home from "./components/Home";
import BugBounty from "./components/BugBounty";
import Footer from "./components/Footer";
import { withTranslation } from "react-i18next";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { createBrowserHistory } from "history";

function App() {
  const replaceHashPath = () => {
    const history = createBrowserHistory();
    const hash = history.location.hash;
    if (hash) {
      const path = hash.replace(/^#/, "");
      if (path) {
        history.replace(path);
      }
    }
  };
  replaceHashPath();

  // 처음에 스크립트 추가
  useEffect(() => {
    const scripts = [
      "js/vendor/aos.js",
      "js/vendor/bootstrap.min.js",
      "js/vendor/smooth-scroll.min.js",
      "https://code.jquery.com/jquery-migrate-1.2.1.min.js",
      "js/slick/slick.min.js",
      "js/vendor/jquery.ajaxchimp.min.js",
      "js/main.js",
    ];
    scripts.forEach((e) => {
      const script = document.createElement("script");
      script.src = e;
      script.async = true;
      document.body.appendChild(script);
    });

    return () => {};
  }, []);
  return (
    <BrowserRouter>
      <div id="preloader">
        <div className="loader">
          <img src="img/bars.svg" width="40" alt="" />
        </div>
      </div>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/bugbounty" element={<BugBounty />} />
      </Routes>
      <a data-scroll href="#header" className="scroll-to-top">
        <i className="arrow_carrot-up" />
      </a>
      <Footer />
    </BrowserRouter>
  );
}

export default withTranslation()(App);
