import { useTranslation } from "react-i18next";
import Carousel from "./carousel/Carousel";
import Particle from "./Particle";

export default function Banner() {
  const { t } = useTranslation();
  let slides = [
    <img src="images/c1.png" alt="1" />,
    <img src="images/c2.png" alt="2" />,
    <img src="images/c3.png" alt="3" />,
  ];
  const particleOptions = {
    fpsLimit: 120,
    height: "700px",
    width: "100%",
    fullScreen: { enable: false },
    particles: {
      color: {
        value: "#ffd661",
      },
      collisions: {
        enable: true,
      },
      move: {
        directions: "none",
        enable: true,
        outModes: {
          default: "bounce",
        },
        random: true,
        speed: 1,
        straight: false,
      },
      number: {
        density: {
          enable: true,
          area: 8000,
        },
        value: 80,
      },
      opacity: {
        value: 0.7,
      },
      shape: {
        type: "circle",
      },
      size: {
        value: { min: 1, max: 5 },
      },
    },
    detectRetina: true,
  };

  return (
    <section id="home" className="main_section">
      <div className="container">
        <div className="">
          <div
            className="flex w-full"
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
          >
            <div className="w-full flex">
              <div className="main_content">
                <h1>
                  {t("title1")} <br />
                  {t("title2")}
                </h1>
                <p className="mt-[30px] mb-25">
                  {t("title-content1")} {t("title-content2")} {t("title-content3")}
                </p>
               
              </div>
              <div className="main_content_img">
                  <img src="../images/app_1.png" alt="app_1" />
                </div>
            </div>
          </div>
        </div>
      </div>
      <Particle id="star-particles" particleOptions={particleOptions} />
    </section>
  );
}
