import Banner from "./Banner";
import Overview from "./Overview";
import Feature1 from "./Feature1";
import Feature2 from "./Feature2";
import Feature3 from "./Feature3";
import News from "./News";
import Download from "./Download";
export default function Home() {
  return (
    <>
      <Banner />
      <Overview />
      <Feature1 />
      <Feature2 />
      <Feature3 />
      <News />
      <Download />
    </>
  );
}
