import React from "react";
import { useTranslation } from 'react-i18next';

export default function BugBounty() {
  const { t } = useTranslation();
  return (
    <div className="mt-[80px] relative text-white">
       <img
        className="absolute left-0 top-[1200px] z-[-1]"
        src="img/f_left.png"
        alt="f_left"
      />
      <img
        className="absolute right-0 top-[100px] z-[-1]"
        src="img/f_right.png"
        alt="f_right"
      />
      <div id="bugbounty" className="text-center h-full flex flex-wrap justify-between items-center w-full my-[100px] mx-auto max-w-[1200px]">
        <div className="text-[24px] md:text-[48px]  font-bold">{t("bugbounty")}</div>
        <div className="btn_default font-bold">
          <a className="flex items-center justify-start" href="mailto:jenny@metalabs.company">
            Summit a Bug
          </a>
        </div>
      </div>
        <div id="bugbounty" className=" items-center w-full my-[100px] mx-auto max-w-[1200px] text-[18px] bg-[rgba(0,0,0,0.2)] rounded-[8px] p-[30px]">
          <div className="text-[22px] md:text-[36px]  font-bold">{t("bugbounty-programoverview")}</div><br />
          {t("bugbounty1")}<br /><br />
          <ul>
            <li>{t("bugbounty2")}</li>
            <li>{t("bugbounty3")}</li>
            <li>{t("bugbounty4")}</li>
            <li>{t("bugbounty5")}</li>
            <li>{t("bugbounty6")}</li>
            <li>{t("bugbounty7")}</li>
            <li>{t("bugbounty8")}</li>
            <li>{t("bugbounty9")}</li>
            <li>{t("bugbounty10")}</li>
            <li>{t("bugbounty11")}</li>
            <li>{t("bugbounty12")}</li>
            <li>{t("bugbounty13")}</li>
          </ul>
        </div>
        <div id="bugbounty" className=" items-center w-full my-[100px] mx-auto max-w-[1200px] text-[18px] bg-[rgba(0,0,0,0.2)] rounded-[8px] p-[30px]">
          <div className="text-[22px] md:text-[36px]  font-bold">{t("bugbounty-reward")}</div><br />
          {t("bugbounty14")}<br /><br />
          {t("bugbounty15")}<br /><br />
          {t("bugbounty16")}<br />
          <ul>
            <li>{t("bugbounty17")}</li>
          </ul>
          <br />
          {t("bugbounty18")}
          <ul>
            <li>{t("bugbounty19")}</li>
          </ul>
          <br />
          {t("bugbounty20")}<br />
          <ul>
            <li>{t("bugbounty21")}
              <ul>
                <li>{t("bugbounty22")}</li>
              </ul>
            </li>
          </ul>
          <br />
          {t("bugbounty24")}<br />
          {t("bugbounty25")}<br />
          {t("bugbounty26")}<br /><br />
          <div className="font-bold">{t("bugbounty-smartcontracts")}</div>
           <div className="flex flex-wrap justify-between items-center w-full mx-auto my-2 text-[18px] bg-[rgba(0,0,0,0.4)] rounded-[8px] p-[30px] font-bold text-white">
            <p className="text-[16px] rounded-[16px] bg-[#f01580] p-[8px] w-[110px] text-center">Critical </p>
            <p className="text-[18px]" >Up to $10,000</p>
          </div>
          <div className="flex flex-wrap justify-between items-center w-full mx-auto my-2 text-[18px] bg-[rgba(0,0,0,0.4)] rounded-[8px] p-[30px] font-bold text-white">
            <p className="text-[16px] rounded-[16px] bg-[#7b15d0] p-[8px] w-[110px] text-center">High </p>
            <p className="text-[18px]" >Up to $5,000</p>
          </div>
          <div className="flex flex-wrap justify-between items-center w-full mx-auto my-2 text-[18px] bg-[rgba(0,0,0,0.4)] rounded-[8px] p-[30px] font-bold text-white">
            <p className="text-[16px] rounded-[16px] bg-[#15d0be] p-[8px] w-[110px] text-center">Medium </p>
            <p className="text-[18px]" >Up to $500</p>
          </div>
          <div className="flex flex-wrap justify-between items-center w-full mx-auto my-2 text-[18px] bg-[rgba(0,0,0,0.4)] rounded-[8px] p-[30px] font-bold text-white">
            <p className="text-[16px] rounded-[16px] bg-[#d07e15] p-[8px] w-[110px] text-center">Low </p>
            <p className="text-[18px]" >Up to $100</p>
          </div>
          <br /><br />
          <div className="font-bold">{t("bugbounty-websites")}</div>
           <div className="flex flex-wrap justify-between items-center w-full mx-auto my-2 text-[18px] bg-[rgba(0,0,0,0.4)] rounded-[8px] p-[30px] font-bold text-white">
            <p className="text-[16px] rounded-[16px] bg-[#f01580] p-[8px] w-[110px] text-center">Critical </p>
            <p className="text-[18px]" >Up to $3,000</p>
          </div>
          <div className="flex flex-wrap justify-between items-center w-full mx-auto my-2 text-[18px] bg-[rgba(0,0,0,0.4)] rounded-[8px] p-[30px] font-bold text-white">
            <p className="text-[16px] rounded-[16px] bg-[#7b15d0] p-[8px] w-[110px] text-center">High </p>
            <p className="text-[18px]" >Up to $1,500</p>
          </div>
          <div className="flex flex-wrap justify-between items-center w-full mx-auto my-2 text-[18px] bg-[rgba(0,0,0,0.4)] rounded-[8px] p-[30px] font-bold text-white">
            <p className="text-[16px] rounded-[16px] bg-[#15d0be] p-[8px] w-[110px] text-center">Medium </p>
            <p className="text-[18px]" >Up to $750</p>
          </div>
        </div>
        <div id="bugbounty" className="items-center w-full my-[100px] mx-auto max-w-[1200px] text-[18px] bg-[rgba(0,0,0,0.2)] rounded-[8px] p-[30px]">
          <div className="text-[22px] md:text-[36px] font-bold">{t("bugbounty-assets")}</div><br />
          <div className="flex flex-wrap justify-between items-center w-full mx-auto my-2 text-[18px] bg-[rgba(0,0,0,0.4)] rounded-[8px] p-[30px] font-bold text-white">
            <a href="https://daystarter.life " target="_blank" rel="noreferrer">https://daystarter.life</a>
            <p>Webiste</p>
          </div>
          <div className="flex flex-wrap justify-between items-center w-full mx-auto my-2 text-[18px] bg-[rgba(0,0,0,0.4)] rounded-[8px] p-[30px] font-bold text-white">
            <a href="https://github.com/TheMetalabs/daystarter-contracts" target="_blank" rel="noreferrer">https://github.com/TheMetalabs/daystarter-contracts</a>
            <p>Smart Contract</p>
          </div>
          <div className="flex flex-wrap justify-between items-center w-full mx-auto my-2 text-[18px] bg-[rgba(0,0,0,0.4)] rounded-[8px] p-[30px] font-bold text-white">
            <a href="/" target="_blank" rel="noreferrer">DAYSTARTER</a>
            <p>iOS/Android Application</p>
          </div>
          <br />
          <div className="text-[18px] font-bold">{t("bugbounty23")}</div>
        </div>
        
        <div id="bugbounty" className=" items-center w-full my-[100px] mx-auto max-w-[1200px] text-[18px] bg-[rgba(0,0,0,0.2)] rounded-[8px] p-[30px]">
          <div className="text-[22px] md:text-[36px]  font-bold">{t("bugbounty-impacts")}</div><br />
          {t("bugbounty27")}<br /><br />
          {t("bugbounty-smartcontracts")}<br /><br />
          <ul>
            <li>{t("bugbounty28")}</li>
            <li>{t("bugbounty29")}</li>
            <li>{t("bugbounty30")}</li>
            <li>{t("bugbounty31")}</li>
            <li>{t("bugbounty32")}</li>
            <li>{t("bugbounty33")}</li>
            <li>{t("bugbounty34")}</li>
            <li>{t("bugbounty35")}</li>
            <li>{t("bugbounty36")}</li>
          </ul><br /><br />
          {t("bugbounty-websites")}<br /><br />
          <ul>
            <li>{t("bugbounty37")}</li>
            <li>{t("bugbounty38")}</li>
            <li>{t("bugbounty39")}</li>
            <li>{t("bugbounty40")}</li>
            <li>{t("bugbounty41")}</li>
            <li>{t("bugbounty42")}</li>
            <li>{t("bugbounty43")}</li>
          </ul><br /><br />
          {t("bugbounty44")}<br />
          {t("bugbounty45")}<br /><br />
          <div className="w-full overflow-auto">
          <table className="w-full text-center" >
            <tr className="text-[18px] bg-[rgba(0,0,0,0.8)] font-bold text-white">
              <td className="w-[16.6%] text-center font-bold py-[10px] ">
                Impact \ Likelihood
              </td>
              <td className="w-[16.6%] text-center font-bold py-[10px]">
                Critical
              </td>
              <td className="w-[16.6%] text-center font-bold py-[10px]">
                High
              </td>
              <td className="w-[16.6%] text-center font-bold py-[10px]">
                Medium
              </td>
              <td className="w-[16.6%] text-center font-bold py-[10px]">
                Low
              </td>
              <td className="w-[16.6%] text-center font-bold py-[10px]">
                Informational
              </td>
            </tr>
            <tr className="text-[18px] bg-[rgba(0,0,0,0.4)] text-white">
              <td className="text-center py-[10px]">
                Critical
              </td>
              <td className="text-center py-[10px]">
                Critical
              </td>
              <td className="text-center py-[10px]">
                Critical
              </td>
              <td className="text-center py-[10px]">
                High
              </td>
              <td className="text-center py-[10px]">
                Medium
              </td>
              <td className="text-center py-[10px]">
                Informational
              </td>
            </tr>
            <tr className="text-[18px] bg-[rgba(0,0,0,0.6)] text-white">
              <td className="text-center py-[10px]">
                High
              </td>
              <td className="text-center py-[10px]">
                High
              </td>
              <td className="text-center py-[10px]">
                High
              </td>
              <td className="text-center py-[10px]">
                High
              </td>
              <td className="text-center py-[10px]">
                Medium
              </td>
              <td className="text-center py-[10px]">
                Informational
              </td>
            </tr>
            <tr className="text-[18px] bg-[rgba(0,0,0,0.4)] text-white">
              <td className="text-center py-[10px]">
                Medium
              </td>
              <td className="text-center py-[10px]">
                Medium
              </td>
              <td className="text-center py-[10px]">
                Medium
              </td>
              <td className="text-center py-[10px]">
                Medium
              </td>
              <td className="text-center py-[10px]">
                Low
              </td>
              <td className="text-center py-[10px]">
                Informational
              </td>
            </tr>
            <tr className="text-[18px] bg-[rgba(0,0,0,0.6)] text-white">
              <td className="text-center py-[10px]">
                Low
              </td>
              <td className="text-center py-[10px]">
                Medium/Low
              </td>
              <td className="text-center py-[10px]">
                Medium
              </td>
              <td className="text-center py-[10px]">
                Low
              </td>
              <td className="text-center py-[10px]">
                Low
              </td>
              <td className="text-center py-[10px]">
                Informational
              </td>
            </tr>
            <tr className="text-[18px] bg-[rgba(0,0,0,0.4)] text-white">
              <td className="text-center py-[10px]">
                None
              </td>
              <td className="text-center py-[10px]">
                Informational
              </td>
              <td className="text-center py-[10px]">
                Informational
              </td>
              <td className="text-center py-[10px]">
                Informational
              </td>
              <td className="text-center py-[10px]">
                Informational
              </td>
              <td className="text-center py-[10px]">
                Informational
              </td>
            </tr>
          </table>
          </div>
        </div>
        <div id="bugbounty" className=" items-center w-full my-[100px] mx-auto max-w-[1200px] text-[18px] bg-[rgba(0,0,0,0.2)] rounded-[8px] p-[30px]">
          <div className="text-[22px] md:text-[36px]  font-bold">{t("bugbounty-outofscope")}</div><br />
          {t("bugbounty46")}<br /><br />
           <ul>
            <li>{t("bugbounty47")}</li>
            <li>{t("bugbounty48")}</li>
            <li>{t("bugbounty49")}</li>
          </ul><br /><br />
           {t("bugbounty50")}<br /><br />
           <ul>
            <li>{t("bugbounty51")}</li>
            <li>{t("bugbounty52")}</li>
            <li>{t("bugbounty53")}</li>
            <li>{t("bugbounty54")}</li>
            <li>{t("bugbounty55")}</li>
            <li>{t("bugbounty56")}</li>
          </ul><br /><br />
          {t("bugbounty-websitesandapps")}<br /><br />
          <ul>
            <li>{t("bugbounty57")}</li>
            <li>{t("bugbounty58")}</li>
            <li>{t("bugbounty59")}</li>
            <li>{t("bugbounty60")}</li>
            <li>{t("bugbounty61")}</li>
            <li>{t("bugbounty62")}</li>
            <li>{t("bugbounty63")}</li>
            <li>{t("bugbounty64")}</li>
            <li>{t("bugbounty65")}</li>
            <li>{t("bugbounty66")}</li>
            <li>{t("bugbounty67")}</li>
            <li>{t("bugbounty68")}</li>
            <li>{t("bugbounty69")}</li>
            <li>{t("bugbounty70")}</li>
            <li>{t("bugbounty71")}</li>
          </ul><br /><br />
          {t("bugbounty72")}<br /><br />
          <ul>
            <li>{t("bugbounty73")}</li>
            <li>{t("bugbounty74")}</li>
            <li>{t("bugbounty75")}</li>
            <li>{t("bugbounty76")}</li>
            <li>{t("bugbounty77")}</li>
            <li>{t("bugbounty78")}</li>
            <li>{t("bugbounty79")}</li>
          </ul>
        </div>
    </div>
  );
}