// This is a React Router v6 app
import { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import SetLanguage from './SetLanguage';
import { useTranslation } from 'react-i18next';

export default function Header() {
  const location = useLocation();
  const { t } = useTranslation();

  useEffect(() => {}, [location]);
  return (
    <header id="header" className="header_area bg-[#1E1C1A] bg-opacity-70">
      <nav className="navbar">
        <div className="max-w-[1460px] m-auto max-md:mx-[10px]">
          <div className="navbar-header">
            <button type="button" className="nav-btn navbar-toggle collapsed z-[100]" data-toggle="collapse" data-target="#navbar" aria-expanded="false" aria-controls="navbar">
              <span className="sr-only">Toggle navigation</span>
              <span className="icon-bar" />
              <span className="icon-bar" />
              <span className="icon-bar" />
            </button>
            <span className="absolute top-[-7px] right-[0px] md-hidden">
              <SetLanguage id={'mobile-language'} />
            </span>
            <a className="brand-container" href="/">
              <img className="brand" src="img/logo.png" alt="DAYSTARTER" />
            </a>
          </div>
          <div id="navbar" className="collapse navbar-collapse navbar-right">
            <ul className="nav navbar-nav nav-menu md:flex md:items-center">
              <li className={location.pathname === '/' ? 'active' : ''}>
                <a href="/">{t('home')}</a>
              </li>
              <li>
                <a data-scroll target="_blank" rel="noreferrer" href={t('whitepaper-link')}>
                  {t('whitepaper')}
                </a>
              </li>
              <li className="max-md-hidden">
                <SetLanguage id={'language'} />
              </li>
            </ul>
          </div>
          {/*/.navbar-collapse */}
        </div>
      </nav>
    </header>
  );
}
