import React from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

const SetLanguage = (props) => {
  const { i18n } = useTranslation();
  const { id } = props;
  let language = 'en';
  language = i18n.language.includes('ko') ? 'ko' : 'en';
  const changeLanguage = (lng) => {
    language = lng.includes('ko') ? 'ko' : 'en';
    i18n.changeLanguage(language);
  };
  return (
    <div id="language" className="select-box">
      <label id="checkbox" htmlFor={`${id}-checkBox`}>
        <img width="25px" src={`/img/${language}.png`} alt={`language`} />
      </label>
      <input type="checkbox" className="btn" id={`${id}-checkBox`} />
      <ul>
        <li id="en" onClick={(e) => changeLanguage('en')}>
          English
        </li>
        <li id="ko" onClick={(e) => changeLanguage('ko')}>
          한국어
        </li>
      </ul>
    </div>
  );
};

export default withTranslation()(SetLanguage);
