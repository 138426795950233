
import { useCallback, useRef } from "react";
import Particles from "react-particles";
import { loadFull } from "tsparticles";

const Particle = (props) => {
  const { id, particleOptions } = props;
  const particlesInit = useCallback(async (engine) => {
      await loadFull(engine);
  }, []);

  const particlesLoaded = useCallback(async (container) => {}, []);

  const particleRef = useRef(null);

  return (
    <div className="particles-container">
      <Particles
          id={id}
          ref={particleRef}
          init={particlesInit}
          loaded={particlesLoaded}
          options={particleOptions}
      />
    </div>
  );
};

export default Particle;
