import Carousel from "./carousel/FeatureCarousel";
import React from "react";
import ReactPlayer from "react-player";
import $ from "jquery";
import { useTranslation } from 'react-i18next';

export default function Feature() {
  const { t } = useTranslation();
  return (
    <div id="overview" className="overview_section relative">
      <div className="text-center h-full flex flex-col justify-center items-center w-full">
        <h2>{t("overview-title")}</h2>
      </div>
      <div className="overview_title">
        <div className="overview_container">
          <p style={{padding: '20px 0', textAlign: 'center', fontWeight: 'bolder'}}>{t("overview-content1")} {t("overview-content2")}</p>
        </div>
      </div>
      <div className="overview_box">
        <div>
          <p style={{padding: '20px 0', textAlign: 'center', fontWeight: 'bolder'}}>{t("overview-circle1")}</p>
        <img
              src="images/overview_clock.png"
              alt="clock"
            />
        </div>
        <div>
        <p style={{padding: '20px 0', textAlign: 'center', fontWeight: 'bolder'}}>{t("overview-circle2")}</p>
        <img
              src="images/overview_bed.png"
              alt="bed"
            />
        </div>
        <div>
        <p style={{padding: '20px 0', textAlign: 'center', fontWeight: 'bolder'}}>{t("overview-circle3")}</p>
        <img
              src="images/overview_coinhand.png"
              alt="coinhand"
            />
        </div>
      </div>
    </div>
  );
}