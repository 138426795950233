import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";

export default function News() {
  const { t } = useTranslation();
  const getNewList = () => {
    const _data = [
      {
        img: "/img/82120d9e0e4531f7c1bce6e84da0fc1f.jpg",
        title: t("news-title1"),
        link: t("news-link1"),
      },
      {
        img: "/img/177545_100878_5411.png",
        title: t("news-title2"),
        link: t("news-link2"),
      },
      {
        img: "/img/cms_temp_article_18172205833039.jpg",
        title: t("news-title3"),
        link: t("news-link3"),
      },
      {
        img: "/img/900605_909805_17.jpg",
        title: t("news-title4"),
        link: t("news-link4"),
      },
      {
        img: "/img/401328_401162_3835.png",
        title: t("news-title5"),
        link: t("news-link5"),
      },
    ];
    const data = [..._data, ..._data, ..._data, ..._data];
    return data.map((m) => {
      return (
        <div
          onClick={() => window.open(m.link, "_blank")}
          className="bg-white shadow-lg rounded-2xl w-[316px] h-[284px] mx-4 cursor-pointer "
        >
          <div className="h-[200px] opacity-[0.7] bg-white rounded-2xl">
            <img
              className="rounded-2xl h-full object-cover w-full"
              src={m.img}
              alt={m.title}
            />
          </div>
          <div className="text-[18px] font-bold flex justify-center items-center h-[80px] px-8">
            {m.title}
          </div>
        </div>
      );
    });
  };
  return (
    <section id="news" className="news_section">
      <div className="w-full z-50">
        <h2>{t('news-title')}</h2>
        <p>{t('news-content')}</p>
        <div className="w-full h-full flex justify-center items-center">
          <motion.div
            key={0}
            animate={{ x: "-20%" }}
            transition={{ repeat: Infinity, duration: 20, ease: "linear" }}
          >
            <div className="flex">{getNewList()}</div>
          </motion.div>
        </div>
      </div>
    </section>
  );
}
