import { useTranslation } from "react-i18next";
import Carousel from "./carousel/Carousel";
import Particle from "./Particle";

export default function Banner() {
  const { t } = useTranslation();
  

  return (
    <section id="feature1" >
      <div className="container">
        <div className="">
          <div
            className="flex w-full wrap"
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
          >
            <div className="w-full flex wrap">
              <div className="feature1_content">
                <h2 className="font-bold">
                  {t("feature1-title")}
                </h2>
                <p className="mt-[30px] mb-25 max-md-hidden">
                  {t("feature1-content")} 
                </p>
                <img src="../images/feature1_bell.png" alt="bell" />
                <p className="mt-[30px] mb-25 md-hidden">
                  {t("feature1-content")} 
                </p>
              </div>
              <div className="feature1_content_img">
                  <img src="../images/app_2.png" alt="app_1" />
                </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
