export default function Footer() {
  return (
    <footer id="footer" className="w-full bg-[#FBB03B]">
      <div className="footer flex flex-col items-center w-full h-full p-10">
        <div className="flex justify-between w-full h-[100%]">
          <div className="flex justify-between w-full text-[24px] font-bold text-white">
            <div className="flex justify-start items-start flex-col w-[160px]">
              <div>Exchange</div>
              <a
                href="https://www.gopax.co.kr/exchange/dst-krw"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-start text-xl mt-8">
                  <img
                    className="w-[30px] h-[30px] mr-4"
                    src="images/gopax.png"
                    alt="gopax"
                  />
                  GOPAX
                </div>
              </a>
            </div>
            <div className="flex justify-start items-start flex-col w-[160px]">
              <div>Coin info</div>
              <a
                href="https://etherscan.io/token/0xe3a46b2bc1d83c731d58cab765d3b45bce789095"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-start text-xl mt-8">
                  <img
                    className="w-[30px] h-[30px] mr-4"
                    src="img/i_etherscan.png"
                    alt="etherscan"
                  />
                  Etherscan
                </div>
              </a>
              <a
                href="https://coinmarketcap.com/ko/currencies/daystarter/"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-start text-xl mt-8">
                  <img
                    className="w-[30px] h-[30px] mr-4"
                    src="img/i_coinmarket.png"
                    alt="CoinMarketCap"
                  />
                  CoinMarketCap
                </div>
              </a>
              <a
                href="https://www.coingecko.com/ko/%EC%BD%94%EC%9D%B8/daystarter"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-start text-xl mt-8">
                  <img
                    className="w-[30px] h-[30px] mr-4"
                    src="img/i_coingecko.png"
                    alt="CoinGecko"
                  />
                  CoinGecko
                </div>
              </a>
            </div>
            <div className="hidden-sm hidden-xs w-[160px]">
              <div>SNS</div>{" "}
              <a
                href="https://twitter.com/Daystarter_Life"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-start text-xl mt-8">
                  <img
                    className="w-[30px] h-[30px] mr-4"
                    src="img/i_twitter.png"
                    alt="Twitter"
                  />
                  Twitter
                </div>
              </a>
              <a
                href="https://discord.gg/life3"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-start text-xl mt-8">
                  <img
                    className="w-[30px] h-[30px] mr-4"
                    src="img/i_discord.png"
                    alt="Discord"
                  />
                  Discord
                </div>
              </a>
              <a
                href="https://t.me/Daystarter_official"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-start text-xl mt-8">
                  <img
                    className="w-[30px] h-[30px] mr-4"
                    src="img/i_telegram.png"
                    alt="Telegram"
                  />
                  Telegram
                </div>
              </a>
              <a
                href="https://medium.com/@daystarter"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-start text-xl mt-8">
                  <img
                    className="w-[30px] h-[30px] mr-4"
                    src="img/i_medium.png"
                    alt="Medium"
                  />
                  Medium
                </div>
              </a>
            </div>
            <div className="hidden-sm hidden-xs w-[160px]">
              Contact
              <div className="flex items-center justify-start text-xl mt-8">
                <a
                  className="flex items-center justify-start"
                  href="mailto:jenny@metalabs.company"
                >
                  <img
                    className="w-[30px] h-[30px] mr-4"
                    src="img/i_email.png"
                    alt="email"
                  />
                  Contact Us
                </a>
              </div>
              <a
                href="https://daystarter.life/bugbounty"
                target="_blank"
                rel="noreferrer"
              >
                <div className="flex items-center justify-start text-xl mt-8">
                  <img
                    className="w-[30px] h-[30px] mr-4"
                    src="img/i_bug.png"
                    alt="bugbounty"
                  />
                  Bug Bounty
                </div>
              </a>
              <a
              href="https://docs.daystarter.life/privacy"
              target="_blank"
            >
              <div className="flex items-center justify-start text-xl mt-8">
                <img
                  className="w-[30px] h-[30px] mr-4"
                  src="img/i_policy.png"
                  alt="policy"
                />
                Privacy policy
              </div>
            </a>
            </div>
          </div>
        </div>
        <div className="flex justify-between w-full text-[24px] font-bold text-white hidden-md hidden-lg hidden-xl mt-10">
          <div className="w-[160px]">
            <div>SNS</div>{" "}
            <a
              href="https://twitter.com/Daystarter_Life"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center justify-start text-xl mt-8">
                <img
                  className="w-[30px] h-[30px] mr-4"
                  src="img/i_twitter.png"
                  alt="Twitter"
                />
                Twitter
              </div>
            </a>
            <a href="https://discord.gg/life3" target="_blank" rel="noreferrer">
              <div className="flex items-center justify-start text-xl mt-8">
                <img
                  className="w-[30px] h-[30px] mr-4"
                  src="img/i_discord.png"
                  alt="Discord"
                />
                Discord
              </div>
            </a>
            <a
              href="https://t.me/Daystarter_official"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center justify-start text-xl mt-8">
                <img
                  className="w-[30px] h-[30px] mr-4"
                  src="img/i_telegram.png"
                  alt="Telegram"
                />
                Telegram
              </div>
            </a>
            <a
              href="https://medium.com/@daystarter"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center justify-start text-xl mt-8">
                <img
                  className="w-[30px] h-[30px] mr-4"
                  src="img/i_medium.png"
                  alt="Medium"
                />
                Medium
              </div>
            </a>
          </div>
          <div className="w-[160px]">
            Contact{" "}
            <div className="flex items-center justify-start text-xl mt-8">
              <a
                className="flex items-center justify-start"
                href="mailto:jenny@metalabs.company"
              >
                <img
                  className="w-[30px] h-[30px] mr-4"
                  src="img/i_email.png"
                  alt="Contact Us"
                />
                Contact Us
              </a>
            </div>
            <a
              href="https://daystarter.life/bugbounty"
              target="_blank"
              rel="noreferrer"
            >
              <div className="flex items-center justify-start text-xl mt-8">
                <img
                  className="w-[30px] h-[30px] mr-4"
                  src="img/i_bug.png"
                  alt="Bug Bounty"
                />
                Bug Bounty
              </div>
            </a>
            <a
              href="https://docs.daystarter.life/privacy"
              target="_blank"
            >
              <div className="flex items-center justify-start text-xl mt-8">
                <img
                  className="w-[30px] h-[30px] mr-4"
                  src="img/i_policy.png"
                  alt="Privacy policy"
                />
                 Privacy policy
              </div>
            </a>
          </div>
        </div>
        <div className="w-full flex justify-center mt-20">
        </div>
        <div className="text-[16px] text-white font-light mt-5">
          © Daystarter Inc.
        </div>
      </div>
    </footer>
  );
}
