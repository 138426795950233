import { useTranslation } from "react-i18next";
import Carousel from "./carousel/Carousel";
import Particle from "./Particle";

export default function Banner() {
  const { t } = useTranslation();
  

  return (
    <section id="download" className="main_section">
          <div
            className="container w-full"
            style={{ paddingLeft: "5%", paddingRight: "5%" }}
          >
                <h4 className="font-bold">
                  {t("download-title")}
                </h4>
                <div className="mt-[30px] flex ">
                  <a 
                    href="https://play.google.com/store/apps/details?id=kr.blueverse.dayStarter" 
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="store_button">
                      <img src="../images/google.png" alt="google" />
                      Google Play
                    </div>
                  </a>
                </div>
            </div>
    </section>
  );
}
